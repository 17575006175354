import { graphql } from "gatsby";
import React from "react";
import Breadcrumb from "~components/layouts/Breadcrumb";
import FormGeneral from "~components/forms/FormGeneral";
import Layout from "~components/layouts/Layout";
import PageHeader from "~components/sections/PageHeader";

export default function Page({ data, location }) {
	const keyword = "Webdesign für Zahnärzte";

	return (
		<Layout
			location={location}
			title={`${keyword} anfragen ▷ Gratis Beratung & Angebot`}
			desc={`${keyword} anfragen. Jetzt Anfragen und gratis Beratung & Angebot einholen.`}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Breadcrumb
				pages={[
					{ name: "Leistungen", to: `/${location.pathname.split("/")[1]}/` },
					{ name: keyword, to: `/${location.pathname.split("/")[1]}/${location.pathname.split("/")[2]}/` },
					{ name: "Anfragen", to: location.pathname },
				]}
			/>
			<PageHeader title={`${keyword} anfragen`}>
				<div className="md:mx-auto md:max-w-lg">
					<FormGeneral
						formName={keyword}
						extraFields={{
							website: true,
						}}
					/>
				</div>
			</PageHeader>
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "components/formGeneral/anfragen_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
